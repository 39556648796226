<template>
    <div class="page">
        <el-form size="small" class="query-form" :inline="true" ref="searchForm" :model="searchForm"
                 label-width="88px" @keyup.enter.native="search(1)">
            <el-form-item prop="departmentName" label="输入搜索：">
                <el-input v-model.trim="searchForm.departmentName" maxlength="50" placeholder="请输入机构/部门名称搜索"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="departmentType" label="类型：">
                <el-select v-model="searchForm.departmentType" placeholder="请选择" clearable>
                    <el-option label="机构" value="0"></el-option>
                    <el-option label="部门" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
            </el-form-item>
            <el-form-item class="f_r">
                <el-button type="primary" icon="el-icon-plus" v-if="hasPermission('sys:department:add')"
                           @click="addEdit(null, 'add')">新增
                </el-button>

            </el-form-item>
        </el-form>
        <div class="bg-white">
            <div class="text_right m_b1">
                <el-button v-if="hasPermission('sys:department:export')" size="small" @click="exportData()">
                    <i class="icon-piliangdaochu iconfont buIcon"/>批量导出
                </el-button>
            </div>
            <div style="height: calc(100vh - 282px);">
                <vxe-table
                        resizable
                        ref="xTree"
                        border="inner"
                        auto-resize
                        height="auto"
                        row-id="id"
                        size="small"
                        show-header-overflow
                        show-overflow
                        highlight-hover-row
                        :tree-config="{}"
                        v-loading="loading"
                        :checkbox-config="{checkStrictly: true}"
                        :data="dataList" @checkbox-change="checkboxChange">
                    <vxe-table-column type="checkbox" width="40px"></vxe-table-column>
                    <vxe-table-column title="机构/部门" field="departmentName" tree-node></vxe-table-column>
                    <vxe-table-column title="类型" field="departmentType">
                        <template slot-scope="scope">
                            {{scope.row.departmentType == 0 ? '机构':'部门'}}
                        </template>
                    </vxe-table-column>
                    <vxe-table-column title="启用状态" field="enabled">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.enabled" active-value="0" inactive-value="1"
                                       @change="enabledChange(scope.row)"></el-switch>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column title="操作" width="150px" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" v-show="hasPermission('sys:department:edit')"
                                       @click="addEdit(scope.row, 'edit')">修改
                            </el-button>
                            <el-button type="text" size="small" v-show="hasPermission('sys:department:del')"
                                       @click="del(scope.row.id)">删除
                            </el-button>
                        </template>
                    </vxe-table-column>
                </vxe-table>
            </div>
        </div>
        <ListForm ref="listForm" @refreshDataList="search()"></ListForm>
    </div>
</template>

<script>
    import ListForm from './listForm'
    import XEUtils from 'xe-utils'

    export default {
        components: {
            ListForm
        },
        data() {
            return {
                searchForm: {
                    departmentName: '',
                    departmentType: '',
                },
                loading: false,
                dataList: [],
                total: 0,
                libraryOpt: [],
                dataListSelections: [],
            }
        },
        mounted() {
            this.search()
        },
        methods: {
            // 检索
            search() {
                this.loading = true
                this.$axios(this.api.auth.getSysDepartment, {}, 'post').then(res => {
                    if (res.status) {
                        this.dataList = res.data
                        this.filterSearch()
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })

            },
            // 过滤
            filterSearch() {
                if (this.searchForm.departmentName) {
                    let options = {children: 'children'}
                    let searchProps = ['departmentName']
                    this.dataList = XEUtils.searchTree(this.dataList, item => searchProps.some(key => XEUtils.toValueString(item[key]).indexOf(this.searchForm.departmentName) > -1), options)
                    this.$nextTick(() => {
                        this.$refs.xTree.setAllTreeExpand(true)
                    })
                }
                if (this.searchForm.departmentType) {
                    let options = {children: 'children'}
                    let searchProps = ['departmentType']
                    this.dataList = XEUtils.searchTree(this.dataList, item => searchProps.some(key => XEUtils.toValueString(item[key]).indexOf(this.searchForm.departmentType) > -1), options)
                    this.$nextTick(() => {
                        this.$refs.xTree.setAllTreeExpand(true)
                    })
                }
            },
            // 启用
            enabledChange(row) {
                this.$axios(this.api.auth.departmentUpdateById, row, 'put').then(data => {
                    if (data && data.status) {
                        this.$message.success(data.msg)
                    } else {
                        this.$message.error(data.msg)
                    }
                    this.search()
                })
            },
            // 新增
            addEdit(row, method) {
                this.$refs.listForm.init(row, method)
            },
            // 删除
            del(id) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.auth.departmentRemoveById + '/' + id, {}, 'delete').then(data => {
                        if (data.status) {
                            this.$message.success(data.msg)
                            this.search()
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            // 多选
            checkboxChange() {
                this.dataListSelections = this.$refs.xTree.getCheckboxRecords()
            },
            // 导出
            exportData() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = this.dataListSelections.map(item => {
                    return item.id
                })
                this.exportExcel(this.api.auth.exportSysDepartment, ids, '组织机构管理数据', 'post')
            },
            //重置
            reset() {
                this.$refs.searchForm.resetFields();
                this.search(1)
            },
        }
    }
</script>

<style scoped>

</style>
